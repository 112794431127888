import React, { useEffect } from "react";
import AboutSP from "../../components/AboutSP";
import FeatureSP from "../../components/FeatureSP/FeatureSP";
import NavbarTwo from "../../components/NavbarTwo/NavbarTwo";
import ServicePageComp from "../../components/ServicePageComp/ServicePageComp";
import TestimonialsSP from "../../components/TestimonialsSP/TestimonialsSP.jsx";
import Services from "../../components/Services/Services";
import "./ServicesPage.css";
import ShowcaseTop from "../../components/ShowcaseTop/ShowcaseTop";
import Testimonials from "../../components/Testimonials/Testimonials";
const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("updated seckjdck");
  }, []);
  const navBlack = true;
  const isServicePage = true;
  return (
    <main>
      {/* <NavbarTwo navBlack = {navBlack}/> */}
      <ShowcaseTop
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Service"
        Pdescription="Wakanda can cover the entire software development process, from product vision discussion to applications design and software enhancement. Our team is prepared to assist you with any custom IT solution or staff augmentation, we are ready to help you at any stage of the project."
      />
      <Services isServicePage={isServicePage} />
      {/* <ServicePageComp /> */}
      {/* <AboutSP/> */}
      {/* <TestimonialsSP/> */}
      <Testimonials />
    </main>
  );
};

export default ServicesPage;
